.container {
  height: 100vh;
  text-align: center;
  .inner {
    padding: 10px 20px;

    flex-direction: column;

    a {
      font-weight: 800;
    }
  }
}
